<template>
  <v-card class="fill-height rounded-xl d-flex align-content-space-between" style="flex-direction: column;">
    <v-toolbar flat class="flex-grow-0" :color="recipe.type == 'popular' ? 'indigo' : recipe.type == 'searchResult' ? 'teal' : 'indigo'" dark>
      <v-toolbar-title>{{recipe.type == 'searchResult' ? `🔎 ${recipe.name}` : recipe.name}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="recipeIsEditable(recipe)" icon :to="`/recipe/${recipe.id}`">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-toolbar>

    <v-img v-if="recipe.thumbnail" 
      :src="recipe.thumbnail"
      max-height="275"
    ></v-img>
    
    <v-card-text>
      <!-- Recipe Ingredients -->
      <v-subheader>Ingredients</v-subheader>
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-chip v-for="item in recipe.ingredients" :key="item.id"
            dark
            :color="ownsIngredient(item.type) ? 'green' : 'red'"
            class="ma-2"
          >
            {{getIngredientName(item)}}
          </v-chip>
        </v-col>
      </v-row>
      <!-- Recipe Steps -->
      <v-subheader>Steps</v-subheader>
      <v-row>
        <v-col cols="12" class="pt-0">
          <ol>
            <li v-for="(step, index) in recipe.steps" :key="index">{{step}}</li>
          </ol>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-caption" v-if="recipe.source">
            Source: <a :href="recipe.source.link">{{recipe.source.name}}</a>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Recipe Actions -->
    <v-card-actions class="mt-auto">
      <!-- <v-btn text>
        Submit to Dictionary
        <v-icon right>mdi-book-plus</v-icon>
      </v-btn> -->
      <v-spacer></v-spacer>

      <!-- Make -->
      <template v-if="user.loggedIn && recipeIsEditable(recipe)">
        <v-btn rounded text color="purple" disabled>
          <v-icon left>
            mdi-glass-cocktail
          </v-icon>
          Make
        </v-btn>
      </template>

      <!-- Share -->
      <template v-if="recipeIsEditable(recipe)">
        <v-bottom-sheet inset>
          <template v-slot:activator=" { on, attrs }">
            <v-btn v-on="on" v-bind="attrs" rounded text color="info">
              <v-icon left>mdi-share</v-icon>
              Share
            </v-btn>
          </template>
          <v-list>
            <v-subheader>Share Recipe</v-subheader>

            <v-dialog width="500" v-model="qrcodeDialog">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-avatar>
                    <v-avatar size="32px">
                      <v-icon>mdi-qrcode</v-icon>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    QR Code
                  </v-list-item-title>
                </v-list-item>
              </template>
              <v-card>
                <v-toolbar flat>
                  <v-toolbar-title>Scan Me!🤳</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col>
                        <v-img class="ma-auto" height="150" width="150" :src="generateQrCode(recipe.id)"></v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    text
                    @click="qrcodeDialog = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-list-item @click="webShare(recipe)">
              <v-list-item-avatar>
                <v-avatar size="32px">
                  <v-icon>mdi-share</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title>
                Share via Phone
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="copyUrl(recipe.id)">
              <v-list-item-avatar>
                <v-avatar size="32px">
                  <v-icon>mdi-content-copy</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title>
                Copy Link
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-bottom-sheet>
      </template>

      <!-- Add to Stash -->
      <template v-if="user.loggedIn && !recipeIsEditable(recipe)">
        <v-btn rounded text color="indigo" @click="addToRecipes(recipe)">
          <v-icon left>mdi-book-plus-multiple-outline</v-icon>
          Add to Recipes
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>
<script>
  import QRCode from 'qrcode';

  export default {
    name: 'RecipeCard',

    data: () => ({
      qrcodeDialog: false,
    }), 

    props: {
      recipe: {
        type: Object
      }, 
      user: {
        type: Object
      }
    },

    methods:{
      recipeIsEditable(recipe){
        if(recipe.id === undefined || recipe.id === null){
          return false;
        }

        if(recipe.type == 'searchResult'){
          return false;
        }

        if(recipe.type == 'popular'){
          return false;
        }

        return true;
        // return recipe.type != 'popular' && recipe.type == 'searchResult'
      },
      ownsIngredient(ingredient){
        return this.$store.getters.getInventoryByIngredient(ingredient);
      },
      getIngredientName(ingredient){
        var normalizedIngredientName = '';
        if(typeof ingredient.type == 'string'){
          let ingredType = ingredient.type;
          normalizedIngredientName = ingredType;
        } else if(typeof ingredient.type == 'object'){
          let ingredType = ingredient.type.name;
          normalizedIngredientName = ingredType;
        }
        return `${ingredient.qty != null ? ingredient.qty : ''} ${ingredient.measurement} - ${normalizedIngredientName}`
      },
      generateQrCode(recipeId){
        var url = `${window.location.origin}/recipe/${recipeId}`

        var returnUrl = 'https://picsum.photos/200';

        QRCode.toDataURL(url, {
          type: 'image/jpeg',
          quality: 1,
          margin: 1,
        }, function(err, url){
            if (err) throw err

            returnUrl = url
        })

        return returnUrl
          // .then(returnURL => {
          //   this.qrLoading
          //   return returnURL;
          // })
          // .catch(err => console.log(err))
      },
      webShare(recipe){

        const shareData = {
          title: recipe.name,
          text: 'Try out this recipe!',
          url: `${window.location.origin}/recipe/${recipe.id}`
        }

        if(navigator.canShare){
          navigator.share(shareData)
            .then(() => {
              console.log('Shared successfullly!')
            })
            .catch(
              console.error
            )
        } else {
          this.$emit('showAlert', {text: 'You can\'t share this way 😥 Try the QRCode?', type: 'info', top: true});
        }

      },
      copyUrl(recipeId){
        var url = `${window.location.origin}/recipe/${recipeId}`

        if(navigator.clipboard){
          try {
            navigator.clipboard.writeText(url)
            this.$emit('showAlert', {text: 'Copied to your clipboard! 👍', type: 'success', top: true});
          } catch (err) {
            console.error(err)
          }
        }
      },
      addToRecipes(recipe){
        this.$emit('addRecipe', recipe);
      },
    }
  }
</script>